<template>
  <section>
    <header>
      <el-row>
        <el-col :md="3" :lg="5">
          <el-button @click="goBack"
            ><i class="el-icon-back">Back </i></el-button
          >
        </el-col>
        <div class="steps-container">
          <el-col :xs="24" :sm="24" :md="21" :lg="19">
            <el-steps
              :active="active"
              finish-status="success"
              :space="500"
              style="width: 350px; margin-left: 300px"
            >
              <el-step
                class="step-text"
                title="Generate Fields"
                @click.native="handleActiveChange(0)"
              ></el-step>
              <el-step
                class="step-text"
                title="Map Fields"
                @click.native="handleActiveChange(1)"
              ></el-step>
            </el-steps>
          </el-col>
        </div>
      </el-row>
    </header>
    <hr />
    <div
      v-loading="responseLoading"
      element-loading-text="Your form template is being generated. Please wait..."
      element-loading-spinner="el-icon-loading"
    >
      <div style="text-align: center" class="mb-5">
        <el-radio-group
          v-model="creationType"
          size="mini"
          class="custom-radio-group"
        >
          <el-radio-button label="Prompt"></el-radio-button>
          <el-radio-button label="Upload File"></el-radio-button>
        </el-radio-group>
      </div>
      <section
        v-if="this.active === 0"
        style="text-align: center; min-height: 400px; max-height: 550px"
      >
        <div class="main-content" v-if="creationType == 'Prompt'">
          <el-card class="box-card" shadow="never">
            <img class="image" src="@/assets/img/Describe.svg" />
            <h4>Describe Your form template</h4>
            <p>Describe your form template in a few words or sentence</p>
            <el-input
              type="textarea"
              :rows="4"
              placeholder="Ex:Create a form template"
              class="input-field"
              v-model="template_creation_prompt"
            >
            </el-input>
            <div class="generate-button">
              <el-button
                type="primary"
                size="medium"
                style="width: 100%"
                :disabled="!template_creation_prompt"
                @click="generateFields"
              >
                Generate Fields
              </el-button>
            </div>
          </el-card>
          <el-dialog
            :visible.sync="isGenerating"
            width="getIsMobile? '50%':'50%'"
            Height="getIsMobile? '50%':'50%'"
            :show-close="false"
            class="plain-header"
          >
            <div class="loading-content">
              <img
                height="100px"
                width="100px"
                :src="iconSrc"
                alt="Loading..."
              />
              <p style="margin-left: 112px">
                Generating fields, please wait...
              </p>
            </div>
          </el-dialog>
        </div>
        <div
          v-else
          v-loading="isGenerating"
          element-loading-text="Generating fields Please wait...!"
          element-loading-spinner="el-icon-loading"
        >
          <el-upload
            v-if="!isFileUploaded"
            class="upload-file-el-upload mt-2"
            drag
            name="logo"
            :on-change="uploadFile"
            action
            :show-file-list="false"
            :auto-upload="false"
            accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf"
          >
            <div class="upload-file mt-5">
              <!-- <div class="icon py-2"> -->
              <img
                src="@/assets/img/icons/upload-file.svg"
                alt="Upload File"
                height="40"
                width="100"
                class="upload-icon"
              />
              <!-- </div> -->
              <p class="text-dark fs-9 mb-0" style="margin: auto">
                Drag &amp; Drop your file here
              </p>
              <p class="fs-9 mb-0" style="color: #babdc2; margin: auto">OR</p>
              <el-button type="primary" style="padding: 6px 10px !important">
                upload File
              </el-button>
            </div>
          </el-upload>
          <el-card v-else class="box-card mt-5" shadow="never">
            <div class="mt-1 mb-4">
              <img src="@/assets/img/mapped_file_uploaded_success.svg" />
              <br /><br />
              <h1>File Uploaded Successfully</h1>
            </div>
            <div class="d-flex flex-row justify-content-center">
              <div class="uploaded-file-display pl-1 pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="green"
                  class="bi bi-check2"
                  viewBox="0 0 16 16"
                  style="font-weight: 600px !important"
                >
                  <path
                    d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                  />
                </svg>
                <img src="@/assets/img/entity_dots.svg" />
                <img
                  v-if="
                    (uploadedFile.raw &&
                      uploadedFile.raw.type == 'image/jpeg') ||
                    uploadedFile.type == 'image/jpg'
                  "
                  width="20"
                  :src="require('@/assets/img/icons/JPG.svg')"
                  alt="icon"
                />
                <img
                  v-else-if="
                    uploadedFile.raw && uploadedFile.raw.type == 'image/png'
                  "
                  width="20"
                  :src="require('@/assets/img/icons/PNG.svg')"
                  alt="icon"
                />

                <img
                  v-else
                  width="20"
                  :src="require('@/assets/img/icons/pdf.svg')"
                  alt="icon"
                />
                <!-- <img src="@/assets/img/entity_excel.svg" /> -->
                <p style="margin: auto auto auto 5px">
                  {{ uploadedFile.name }}
                </p>
                <div class="flex-grow-1"></div>
                <div style="cursor: pointer">
                  <a @click="removeFile"
                    ><i class="el-icon-circle-close"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="generate-button1">
              <el-button
                type="primary"
                size="medium"
                style="width: 20%"
                class="mt-1"
                :disabled="!template_creation_prompt"
                @click="generateFields"
              >
                Generate Fields
              </el-button>
            </div>
          </el-card>
        </div>
      </section>

      <section v-if="this.active === 1">
        <div
          v-loading="isGenerating"
          element-loading-text="Regenerating Your Form template. Please wait...!"
          element-loading-spinner="el-icon-loading"
        >
          <h1>
            <div>
              <div
                class="table-container"
                style="width: 70%; margin-left: 200px; height: 350px"
              >
                <el-scrollbar
                  style="
                    max-height: 350px;
                    overflow-x: auto;
                    overflow-y: auto;
                    scrollbar-width: thin;
                  "
                >
                  <el-table :data="preparedData" border class="check">
                    <el-table-column prop="label" label="Field Name">
                      <template v-slot="scope">
                        <div v-if="scope.row.data_table_parent_name">
                          <el-tooltip :content="getTooltipContent(scope.row)">
                            <span>
                              {{
                                scope.row.data_table_parent_name +
                                "-" +
                                scope.row.label
                              }}
                            </span>
                          </el-tooltip>
                        </div>
                        <div v-else-if="scope.row.label == 'upload_file'">
                          Upload File
                        </div>
                        <div v-else>
                          {{ scope.row.label }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="Field Type">
                      <template v-slot="scope">
                        <div class="d-flex flex-row field-type">
                          <el-select
                            v-if="
                              scope.row.template_id.includes('newTemplate') ===
                              true
                            "
                            v-model="scope.row.inputType"
                            placeholder="Select field"
                            @change="validateInputFields(scope.row)"
                            clearable
                          >
                            <el-option
                              v-for="field in allFormFields"
                              :key="field.key"
                              :label="field.name"
                              :value="field.key"
                            >
                              <div class="field-icon-container">
                                <img
                                  :src="
                                    require('@/assets/img/fields/' +
                                      field.img_url)
                                  "
                                  style="width: 20px; padding-right: 10px"
                                />
                                {{ field.name }}
                              </div>
                            </el-option>
                          </el-select>
                          <el-select
                            v-else
                            v-model="scope.row.template_filed_id"
                            placeholder="Select field"
                            filterable
                            clearable
                          >
                            <el-option
                              v-for="(field, index) in getTemplateFields(
                                scope.row
                              )"
                              :key="field._id + index + field.label"
                              :label="field.label"
                              :value="field.key"
                              :disabled="checkIsFieldSelected(field)"
                            >
                              <div v-if="field && field.inputType == 'ENTITY'">
                                <span>{{ field.label }}</span>
                                <span
                                  style="color: #f754a2; font-weight: 800"
                                  class="ml-2"
                                >
                                  ENTITY
                                </span>
                              </div>
                              <div v-else>
                                {{ field.label }}
                              </div>
                            </el-option>
                          </el-select>
                          <el-select
                            v-if="scope.row.inputType == 'ENTITY'"
                            v-model="scope.row.entity_id"
                            placeholder="Select entity"
                            filterable
                            class="ml-2"
                          >
                            <el-option
                              v-for="field in companyEntities"
                              :key="field._id"
                              :label="field.name"
                              :value="field._id + '#old'"
                            >
                              {{ field["name"] }}
                            </el-option>
                          </el-select>
                          <el-select
                            v-else-if="scope.row.inputType == 'CURRENCY'"
                            v-model="scope.row.currency_type"
                            placeholder="Select Currency Type"
                            class="ml-05"
                          >
                            <el-option
                              v-for="field in currencyTypes"
                              :key="field"
                              :label="field"
                              :value="field"
                            >
                            </el-option>
                          </el-select>
                          <el-select
                            v-else-if="scope.row.inputType == 'ENTITY_TABLE'"
                            v-model="scope.row.entity_id"
                            placeholder="Select entity"
                            filterable
                            class="ml-2"
                          >
                            <el-option
                              v-for="field in companyEntities"
                              :key="field._id"
                              :label="field.name"
                              :value="field._id"
                            >
                              {{ field["name"] }}
                            </el-option>
                          </el-select>
                          <!-- <div
                            v-else-if="scope.row.inputType == 'ENTITY_VARIABLE'"
                          >
                            <el-button
                              @click="
                                validateInputFields(scope.row, scope.$index)
                              "
                              size="mini"
                            >
                              configure
                            </el-button>
                          </div> -->
                          <div
                            v-else-if="
                              scope.row.inputType == 'AUTO_INCREMENT_NUMBER'
                            "
                          >
                            <el-input
                              v-model="scope.row.auto_increment_prefix"
                              class="ml-05"
                              placeholder="Prefix"
                            >
                            </el-input>
                            <el-input
                              v-model="scope.row.auto_increment_starting_value"
                              type="number"
                              class="ml-05"
                              placeholder="Starting Number"
                              @input="handleAutoIncrementChange(scope.row)"
                            >
                            </el-input>
                          </div>
                          <div
                            v-else-if="
                              [
                                'ENTITY_VARIABLE',
                                'SELECT',
                                'MULTI_SELECT',
                                'RADIO_BUTTON_GROUP',
                                'CHECKBOX_GROUP',
                              ].includes(scope.row.inputType) &&
                              scope.row.template_id.includes('newTemplate')
                            "
                          >
                            <el-button
                              @click="
                                configureFieldItems(scope.row, scope.$index)
                              "
                              size="mini"
                            >
                              configure
                            </el-button>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="Required">
                      <template v-slot="scope">
                        <el-checkbox
                          v-model="scope.row.is_required"
                        ></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column label="Select">
                      <template slot-scope="scope">
                        <el-checkbox
                          :disabled="true"
                          v-model="scope.row.is_selected"
                        >
                        </el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-scrollbar>
              </div>
              <footer>
                <div class="fixed-footer">
                  <div class="actions">
                    <div class="req-changes">
                      <span>Request for changes</span><br />
                      <div class="input-container">
                        <el-input
                          type="textarea"
                          :row="2"
                          v-model="changes_prompt"
                          placeholder="Request for changes"
                          class="input-field-icon"
                        >
                        </el-input>
                        <div class="send-btn">
                          <el-button
                            size="medium"
                            type="primary"
                            :disabled="!changes_prompt || responseLoading"
                            @click="generateResponse(true)"
                            icon="el-icon-position"
                          ></el-button>
                        </div>
                      </div>
                    </div>
                    <div class="regenerate">
                      <span>or</span>
                      <el-button
                        type="primary"
                        size="medium"
                        class="reg-btn"
                        style="margin-left: 10px"
                        @click="generateResponse()"
                      >
                        {{ getIsMobile ? " " : "Regenerate"
                        }}<i class="el-icon-refresh"></i
                      ></el-button>
                    </div>
                    <div class="template-questionnaire">
                      <el-row
                        type="flex"
                        justify="space-between"
                        align="middle"
                        class="mt-1"
                      >
                        <el-col :span="10">
                          <el-checkbox v-model="use_interactive_questionare">
                            Use this template for questionnaire
                          </el-checkbox>
                        </el-col>
                      </el-row>
                      <el-row
                        type="flex"
                        justify="space-between"
                        align="middle"
                        v-if="use_interactive_questionare"
                      >
                        <el-col :span="10">
                          <el-select v-model="interactive_questionare_type">
                            <el-option
                              label="Horizontal"
                              :value="'horizontal'"
                            ></el-option>

                            <el-option
                              label="Vertical"
                              :value="'vertical'"
                            ></el-option>
                          </el-select>
                        </el-col>
                      </el-row>
                    </div>

                    <div class="column-box">
                      <span>No. of columns</span><br />
                      <el-input-number
                        size="mini"
                        :min="1"
                        :max="3"
                        v-model="numberOfColumns"
                        @change="handleColumnChange"
                      >
                      </el-input-number>
                    </div>
                  </div>
                  <el-button
                    type="primary"
                    size="large"
                    @click="openDialog"
                    class="responsive-button"
                    style="width: 70%; margin: 20px 0px 10px 198px"
                    >Create Form Template</el-button
                  >
                </div>
              </footer>
            </div>
          </h1>
        </div>
      </section>
    </div>

    <dialog-component
      :title="'Configure Options (' + currentRow.label + ')'"
      :visible="optionsConfigVisible"
      :containerMaxWidth="getIsMobile ? '70%' : '60%'"
      :containerMinHeight="'30%'"
      :containerMinWidth="'40%'"
    >
      <div class="entity-variable-container mt-1 mb-10">
        <div>Configure your Options for {{ currentRow.label }} :</div>
        <el-select
          v-model="currentRow.options"
          class="config-options-select mt-10"
          placeholder="Customize options"
          size="small"
          multiple
          clearable
          filterable
          default-first-option
          allow-create
        >
          <el-option
            v-for="item in currentRow.options && currentRow.options"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
      <div class="card-footer bg-white">
        <el-button
          type="primary"
          class="align-right"
          :disabled="
            !(currentRow && currentRow.options && currentRow.options.length)
          "
          size="mini"
          @click="checkForOptions()"
          >Save</el-button
        >
      </div>
    </dialog-component>
    <dialog-component
      :title="entityVariableField.excel_field"
      :visible="entityVarDialogVisible"
      :containerMaxWidth="getIsMobile ? '70%' : '60%'"
      @before-close="entityVarDialogVisible = false"
    >
      <div class="entity-variable-container mt-1 mb-10">
        <div>
          <label for="valueOfEntity">Entities</label><br />
          <el-select
            v-model="entityVariableField['relationship_key']"
            placeholder="Select Entity"
            id="valueOfEntity"
            style="width: 90%"
            @change="getSelectedEntityFieldValue()"
            clearable
          >
            <el-option
              v-for="(entityField, index) in currentTemplateEntityFields"
              :key="entityField.excel_field + index"
              :label="entityField.excel_field"
              :value="entityField.entity_id + '#' + entityField.excel_field"
            >
              <span
                >{{ entityField.excel_field
                }}<span style="color: #8492a4; font-weight: 700">
                  Template variable</span
                ></span
              >
            </el-option>
          </el-select>
        </div>
        <br />
        <div>
          <label for="varibleValueOfEntity">Entity Variable</label><br />
          <el-select
            v-model="entityVariableField['entityVariableInfo']"
            placeholder="Select Variable"
            id="varibleValueOfEntity"
            style="width: 90%"
            clearable
          >
            <el-option
              v-for="(field, index) in selectedEntityAllFields"
              :key="field.key + index"
              :label="field.template_name + '->' + field.label"
              :value="
                field.template_id + '#' + field.key + '#' + field.input_type
              "
            >
            </el-option>
          </el-select>
        </div>
        <br />
        <div>
          <label for="fieldAssignable">Is this field ?</label>
          <el-radio-group
            id="fieldAssignable"
            v-model="entityVariableField['field_assignable']"
          >
            <el-radio label="read_only">Read only</el-radio>
            <el-radio label="editable">Editable</el-radio>
            <el-radio label="update_parent">Update parent</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="entityVarDialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="setPropertiesForEntityVariable"
          :disabled="disableEntityVariableButton"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
  </section>
</template>

<script>
import templateBuilderHelper from "../../mixins/templateBuilderHelper";
import axios from "axios";
import nodeAxios from "@/config/axios";
import { mapGetters } from "vuex";
import { postAPICall } from "../../helpers/httpHelper";
export default {
  name: "new_template_with_ai",
  data() {
    return {
      active: 0,
      creationType: "Prompt",
      loadingText: "Generating your template. Please wait...",
      isGenerating: false,
      iconSrc: "",
      myFieldLabelKeys: {},
      dataTableFieldIndexes: {},
      isFileUploaded: false,
      uploadedFile: null,
      AddDialogVisible: false,
      formTemplateName: "",
      dialogVisible: false,
      entityVarDialogVisible: false,
      optionsConfigVisible: false,
      currentRow: {},
      changes_prompt: "",
      template_creation_prompt: "",
      template_name: "",
      numberOfColumns: 3,
      requestForChanges: "",
      columns: 3,
      startingNum: "",
      increPrefix: "",
      increStartingNum: null,
      companyEntities: [],
      allCompanyTemplatesData: [],
      entityVariableField: {},
      currentTemplateEntityFields: [],
      selectedEntityAllFields: [],
      scopeData: [],
      preparedData: [],
      allFieldLabelKeys: {},
      use_interactive_questionare: false,
      interactive_questionare_type: "",
      allFormFields: [
        {
          key: "SINGLE_LINE_TEXT",
          name: "Single Line Text",
          img_url: "SingleLine.svg",
        },
        {
          key: "MULTI_LINE_TEXT",
          name: "Multi Line Text",
          img_url: "MultiLine.svg",
        },
        { key: "SELECT", name: "Select", img_url: "Select.svg" },
        { key: "NUMBER", name: "Number", img_url: "Number.svg" },
        {
          key: "MULTI_SELECT",
          name: "Multiple Select",
          img_url: "MultiSelect.svg",
        },
        { key: "DATE", name: "Date", img_url: "Date.svg" },
        { key: "DATE_RANGE", name: "Date Range", img_url: "Date.svg" },
        { key: "TIME", name: "Time", img_url: "Date.svg" },
        { key: "TIME_RANGE", name: "Time Range", img_url: "Date.svg" },
        {
          key: "DATE_TIME_RANGE",
          name: "Date Time Range",
          img_url: "Date.svg",
        },
        { key: "DATE_TIME", name: "Date Time", img_url: "Date.svg" },
        { key: "YES_OR_NO", name: "Yes or No", img_url: "YesNo.svg" },
        { key: "CHECKBOX", name: "Checkbox", img_url: "CheckBox.svg" },
        {
          key: "CHECKBOX_GROUP",
          name: "Checkbox Group",
          img_url: "CheckBox.svg",
        },
        { key: "RADIO", name: "Radio", img_url: "CheckBox.svg" },
        {
          key: "RADIO_BUTTON_GROUP",
          name: "Radio Group",
          img_url: "CheckBox.svg",
        },
        { key: "PHONE_COUNTRY_CODE", name: "Phone", img_url: "Number.svg" },
        { key: "CURRENCY", name: "Currency", img_url: "Number.svg" },
        { key: "IMAGE", name: "Image", img_url: "File.svg" },
        { key: "FILE", name: "File", img_url: "File.svg" },
        { key: "FORMULA", name: "Formula", img_url: "MultiLine.svg" },
        {
          key: "AGGREGATE_FUNCTION",
          name: "Aggregate Function",
          img_url: "MultiLine.svg",
        },
        {
          key: "AUTO_INCREMENT_NUMBER",
          name: "Auto Increment Number",
          img_url: "SingleLine.svg",
        },
        { key: "ENTITY", name: "Entity", img_url: "glodal_variable.svg" },
        {
          key: "ENTITY_VARIABLE",
          name: "Entity Variable",
          img_url: "glodal_variable.svg",
        },
        {
          key: "ENTITY_TABLE",
          name: "Entity Table",
          img_url: "glodal_variable.svg",
        },
      ],
      currencyTypes: ["USD", "CAD", "AUD", "INR", "EUR"],
      responseLoading: false,
    };
  },
  mixins: [templateBuilderHelper],
  components: {
    // loadingComponentVue: () => import("../loadingComponent.vue"),
  },
  async mounted() {
    const templateNameFromQuery = this.$route.query.template_name;
    if (this.$route.query.isFromFormBuilder === "true") {
      this.template_creation_prompt = `Create template for ${templateNameFromQuery}`;
    } else {
      this.template_creation_prompt = `create template for ${this.$route.query.template_name}`;
    }
    [this.companyEntities, this.allCompanyTemplatesData] = await Promise.all([
      this.fetchAllEntities(false),
      this.fetchAllTemplates(true),
    ]);
  },
  methods: {
    handleActiveChange(target) {
      if (target < this.active) {
        switch (target) {
          case 0:
            break;
          case 1:
            break;
        }
        this.active = target;
      } else {
        this.$message.warning("Please click next");
      }
    },
    async goBack() {
      this.$confirm(
        "Are you sure to go back? All you data will be lost.",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.$router.push("/template");
      });
    },
    async generateFields() {
      this.iconSrc = require("@/assets/Animation.gif");
      await this.generateResponse();
    },
    openFormDialog() {
      this.AddDialogVisible = false;
      this.dialogVisible = true;
    },
    handleAutoIncrementChange(data) {
      if (["", null, undefined].includes(data.auto_increment_starting_value)) {
        data.auto_increment_starting_value = 1;
      }
    },
    getSelectedEntityFieldValue() {
      this.selectedEntityAllFields = [];
      if (
        this.entityVariableField.relationship_key !== "" &&
        this.companyEntities
      ) {
        let entityField = this.companyEntities.find((e) => {
          if (
            e &&
            e._id &&
            this.entityVariableField["relationship_key"].split("#")[0] === e._id
          ) {
            return true;
          } else {
            return false;
          }
        });
        if (entityField && entityField.templates) {
          entityField.templates.forEach((te) => {
            let template = this.allCompanyTemplatesData.find((t) => {
              if (t && t._id === te.template_id) {
                return true;
              } else {
                return false;
              }
            });
            if (
              template &&
              template.sections[0] &&
              template.sections[0].fields
            ) {
              template.sections[0].fields.forEach((fie) => {
                fie.template_id = template._id;
                fie.template_name = template.name;
                this.selectedEntityAllFields.push(fie);
              });
            }
          });
        }
      }
    },
    setPropertiesForEntityVariable() {
      this.preparedData.map((header) => {
        if (
          header &&
          header.inputType == "ENTITY_VARIABLE" &&
          header.excel_field == this.entityVariableField.excel_field
        ) {
          header.entityVariableInfo =
            this.entityVariableField["entityVariableInfo"];
          header.relationship_key =
            this.entityVariableField["relationship_key"];
        }
      });
      this.entityVarDialogVisible = false;
    },

    async generateResponse(isChanges = false) {
      try {
        this.isGenerating = true;
        let params = {
          session_id: `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`,
          flag: !isChanges,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        if (isChanges)
          params["input_type"] =
            this.creationType == "Prompt" ? "prompt" : "file";
        if (this.creationType == "Prompt" || this.preparedData?.length) {
          params["template_name"] = isChanges
            ? this.changes_prompt
            : this.template_creation_prompt;
        } else {
          params["url"] = await this.getDownloadUrl();
        }
        let response = await axios.post(
          "https://ai.esigns.io/form_template/",
          params
        );
        if (response?.data) {
          this.$message.success("Fields generated successfully");
          this.mapResponseFields(response.data);
        }
        this.active = 1;
        this.changes_prompt = "";
        this.isGenerating = false;
      } catch (err) {
        this.isGenerating = false;
        this.$message.warning("Error generating template.Please try again..");
      }
    },
    mapResponseFields(data) {
      this.preparedData = [];
      this.template_name = data.template_name;
      let allFields = [];
      this.myFieldLabelKeys = {};
      this.dataTableFieldIndexes = {};
      data.fields.map((field, index) => {
        if (field.type == "DATA_TABLE" && field.columns?.length) {
          this.dataTableFieldIndexes[field.name] = index;
          field.columns.map((e) => {
            e.data_table_parent_name = field.name;
            allFields.push(e);
          });
        } else {
          allFields.push(field);
        }
      });
      console.log("all fields", allFields);
      allFields.forEach((field, i) => {
        let parsedField = this.getDefaultFieldData(field, i);
        this.myFieldLabelKeys[parsedField.excel_field] = parsedField;
        this.preparedData.push(parsedField);
      });
    },
    getDefaultFieldData(field, index) {
      if (field.type == "FILE" && field.default) {
        field.name = "Upload Your File";
      }
      let keyValue = this.makeSlug(field.name);
      return {
        excel_field: field.name,
        template_id: `newTemplate#${this.template_name}#${this.template_name}`,
        // template_filed_id: "",
        template_filed_id: keyValue,
        inputType: field.type ? field.type : "SINGLE_LINE_TEXT",
        entity_id: "",
        isPrimaryField: index == 0 ? true : false,
        // fieldRepeatedCount: count,
        currency_type: field.currency_type ? field.currency_type : "USD",
        options: field.options ? field.options : [],
        label: field.name,
        // key: "",
        key: keyValue,
        entityVariableInfo: "",
        relationship_key: "",
        field_assignable: "read_only",
        auto_increment_prefix: "",
        auto_increment_starting_value: "001",
        is_required: field.required ? field.required : false,
        is_selected: true,
        data_table_columns: [],
        data_table_parent_name: field.data_table_parent_name || "",
        use_ai: field.type == "FILE" && field.default ? true : false,
        ai_autofill_fields: [],
        ...(field.type == "FORMULA" &&
          field.selected_fields && {
            selected_fields: this.getSelectedFieldsForFormula(
              field.selected_fields,
              field,
              this.myFieldLabelKeys
            )[0].filter((e) => !["", undefined, null].includes(e)),
            formula: this.getSelectedFieldsForFormula(
              field.selected_fields,
              field,
              this.myFieldLabelKeys
            )[1],
          }),
        ...(field.type == "AGGREGATE_FUNCTION" &&
          field.aggregate_fields && {
            function_type: this.getAggregateFunctionType(field.aggregate_fields?.function_type) || "Sum",
            aggregate_self_template: true,
            fields_type: field.aggregate_fields?.field_type || "INDIVIDUAL",
            selected_fields: [
              this.myFieldLabelKeys[field.aggregate_fields.selected_field]?.key,
            ].filter((e) => !["", undefined, null].includes(e)),
          }),
        ...(field.type == "AGGREGATE_FUNCTION" &&
          field.aggregate_fields?.attached_data_table_key && {
            attached_data_table_key: this.makeSlug(
              field.aggregate_fields.attached_data_table_key
            ),
            selected_fields: [
              this.myFieldLabelKeys[field.aggregate_fields.table_field]?.key,
            ].filter((e) => !["", undefined, null].includes(e)),
          }),
      };
    },
    uploadFile(file) {
      this.isFileUploaded = true;
      this.uploadedFile = file;
    },
    removeFile() {
      this.isFileUploaded = false;
      this.uploadedFile = null;
    },
    async getDownloadUrl() {
      var formData = new FormData();
      [this.uploadedFile].forEach((el) => {
        formData.append("files", el.raw !== undefined ? el.raw : el);
      });
      formData.append("path", "temporary-files");
      formData.append("include_presigned_urls", true);
      let filesData = await nodeAxios.post(`/presignMultiUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      let url;
      if (Object.keys(filesData.data.urlsObject).length) {
        url =
          filesData.data.urlsObject[Object.keys(filesData.data.urlsObject)[0]]
            ?.downloadURL;
      }
      if (!url) return this.$message.error("Error Uploading File");
      return url;
    },
    handleColumnChange(value) {
      this.numberOfColumns = value;
    },
    getTemplateFields(row) {
      if (
        row &&
        row.template_id &&
        this.companyTemplates &&
        this.companyTemplates.length
      ) {
        let foundedTemp = this.companyTemplates.find((temp) => {
          if (
            temp &&
            temp._id &&
            row.template_id &&
            temp._id == row.template_id
          ) {
            return true;
          } else {
            return false;
          }
        });
        let contentFields = [
          "HEADING",
          "PARAGRAPH",
          "HTML_CONTENT",
          "SINGLE_LINE_CONTENT",
          "VIDEO",
          "ACTION_BUTTON",
          "HORIZONTAL_LINE",
          "DIVISION",
        ];
        if (
          foundedTemp &&
          foundedTemp.sections[0] &&
          foundedTemp.sections[0].fields
        ) {
          let fields = [];
          foundedTemp.sections[0].fields.forEach((field) => {
            if (
              field &&
              field.input_type &&
              contentFields.includes(field.input_type) === false
            ) {
              fields.push(field);
            }
          });

          return fields.map((e) => {
            e.template_id = row.template_id;
            return e;
          });
        } else {
          return [];
        }
      }
      return [];
    },
    getTooltipContent(data) {
      return `${data.data_table_parent_name} is table name & ${data.label} is field name`;
    },
    configureFieldItems(row, index) {
      switch (row.inputType) {
        case "ENTITY_VARIABLE":
          this.validateInputFields(row, index);
          break;
        case "SELECT":
        case "MULTI_SELECT":
        case "RADIO_BUTTON_GROUP":
        case "CHECKBOX_GROUP":
          this.optionsConfigureDialog(row);
          break;
      }
    },
    optionsConfigureDialog(row) {
      this.currentRow = row;
      this.optionsConfigVisible = true;
    },
    checkForOptions() {
      if (!this.currentRow.options?.length) {
        return this.$message.warning("Please add at least one option");
      }
      this.optionsConfigVisible = false;
    },
    validateInputFields(row, index) {
      this.incrementFieldRowIndex = index;
      if (row && row.inputType === "AUTO_INCREMENT_NUMBER") {
        this.dialogVisible = true;
        this.increPrefix = "";
        this.increStartingNum = null;
        this.considerExcelData = false;
      } else if (row && row.inputType === "ENTITY_VARIABLE") {
        this.entityVariableField = null;
        let entityFields = this.preparedData.filter((e) => {
          if (
            e &&
            // (e.inputType === "ENTITY" ||
            //   e.entityVariableInfo.split("#")[2] == "ENTITY") &&
            e.inputType === "ENTITY" &&
            e.template_id.includes("newTemplate") &&
            row.template_id === e.template_id
          ) {
            return true;
          } else {
            return false;
          }
        });
        this.currentTemplateEntityFields = entityFields ? entityFields : [];
        this.entityVariableField = row;
        this.entityVarDialogVisible = true;
      }
    },

    resetIncrementFields() {
      this.dialogVisible = false;
      this.increPrefix = "";
      this.increStartingNum = null;
    },
    setIncrementProperties() {
      if (this.incrementFieldRowIndex !== null) {
        this.tableData[this.incrementFieldRowIndex].auto_increment_prefix =
          this.increPrefix;
        this.tableData[
          this.incrementFieldRowIndex
        ].auto_increment_starting_value = this.increStartingNum;
      }
      this.dialogVisible = false;
    },
    async openDialog() {
      try {
        const isValid = await this.getMappingScreenData();
        if (isValid) {
          this.responseLoading = true;
          let dataTableFieldsObject = {};
          let allFields = [];
          this.allFieldLabelKeys = {};
          this.preparedData.map((e, i) => {
            if (e.template_id.toString().includes("#")) {
              e.template_filed_id = this.makeSlug(e.excel_field);
              e.key = e.template_filed_id;
              this.allFieldLabelKeys[e.label] = e.key;
            } else {
              switch (e.template_filed_id) {
                case "name":
                  e.excel_field = "Name";
                  break;
                case "first_name":
                  e.excel_field = "First Name";
                  break;
                case "last_name":
                  e.excel_field = "Last Name";
                  break;
                case "email":
                  e.excel_field = "Email";
                  break;
              }
              e.key = e.template_filed_id;
              this.allFieldLabelKeys[e.label] = e.key;
            }
            if (e.data_table_parent_name) {
              if (
                Object.keys(dataTableFieldsObject)?.includes(
                  e.data_table_parent_name
                )
              ) {
                dataTableFieldsObject[
                  e.data_table_parent_name
                ]?.data_table_columns.push(e);
              } else {
                let template_filed_id = this.makeSlug(e.data_table_parent_name);
                dataTableFieldsObject[e.data_table_parent_name] = {
                  ...this.getDefaultFieldData(
                    {
                      name: e.data_table_parent_name,
                      type: "DATA_TABLE",
                    },
                    i
                  ),
                  data_table_columns: [e],
                  template_filed_id: template_filed_id,
                  key: template_filed_id,
                };
              }
            } else {
              allFields.push(e);
            }
          });
          (Object.keys(dataTableFieldsObject) || []).map((e) => {
            allFields.splice(
              this.dataTableFieldIndexes[dataTableFieldsObject[e]?.label],
              0,
              dataTableFieldsObject[e]
            );
            this.allFieldLabelKeys[dataTableFieldsObject[e].label] =
              dataTableFieldsObject[e].key;
          });
          let field = this.preparedData.find((fd) => fd.use_ai);
          if (field) {
            field.ai_autofill_fields = this.getSupportedFieldKeys();
          }
          let params = {
            fields: allFields,
            template_name: this.$route.query.template_name,
            template_id: `newTemplate#${this.template_name}#${this.template_name}`,
            templateColumnValue: this.numberOfColumns,
            use_interactive_questionare: this.use_interactive_questionare,
            interactive_questionare_type: this.interactive_questionare_type,
          };
          let response = await postAPICall(
            "POST",
            "/template/createTemplateFromJson",
            params
          );
          if (response.data?._id) {
            if (this.$route.query.isFromFormBuilder == "true") {
              if (
                this.$route?.query?.isFromAdd === "true" ||
                this.$route?.query?.isFromAdd === true
              ) {
                return this.$router.push(
                  "/formBuilders/add?templateId=" + response.data?._id
                );
              } else {
                return this.$router.push(
                  "/formBuilders/" +
                    this.$route.query.formId +
                    "?templateId=" +
                    response.data?._id
                );
              }
            }

            this.$router.push({
              name: "Edit Templates",
              params: { template_id: response.data._id },
              query: {
                key: Math.floor(Math.random() * 100000),
              },
            });
          }
        }
      } catch (err) {
        this.$message.error("Error while creating template");
        console.log("err", err);
      }
    },
    getSupportedFieldKeys() {
      let notSupportedFieldTypes = [
        "FILE",
        "ENTITY_VARIABLE",
        "PAYMENT_VARIABLE",
        "FORMULA",
        "AGGREGATE_FUNCTION",
        "CONCATENATE",
        "AUTO_INCREMENT_NUMBER",
      ];
      return this.preparedData
        .map((e) => {
          if (
            !notSupportedFieldTypes.includes(e.inputType) &&
            e.type != "CONTENT"
          ) {
            if (e.data_table_parent_name) {
              return (
                this.allFieldLabelKeys[e.data_table_parent_name] + "#" + e.key
              );
            }
            return e.key;
          }
        })
        .filter((e) => !["", null, undefined].includes(e));
    },
    async getMappingScreenData() {
      let notSelectedAllInputTypes = false;
      if (Array.isArray(this.preparedData)) {
        this.preparedData.forEach((fie) => {
          if (
            fie.template_id?.includes("newTemplate") &&
            fie.inputType === ""
          ) {
            notSelectedAllInputTypes = true;
          }
        });

        if (notSelectedAllInputTypes) {
          this.$notify({
            title: "Error",
            message: "Please select an input type for all fields",
            type: "error",
          });
          return false;
        }
      }
      let entityVariablesNotFilled = false;
      let entityFieldsNotFilled = false;
      this.preparedData.forEach((e) => {
        if (
          e.inputType == "ENTITY_VARIABLE" &&
          (!e.entityVariableInfo || !e.relationship_key)
        ) {
          entityVariablesNotFilled = true;
        } else if (e.inputType == "ENTITY" && !e.entity_id) {
          entityFieldsNotFilled = true;
        }
      });
      if (entityVariablesNotFilled) {
        this.$notify({
          title: "Error",
          message: "Please configure all the Entity variables information",
          type: "error",
        });
        return false;
      } else if (entityFieldsNotFilled) {
        this.$notify({
          title: "Error",
          message: "Please configure all the Entity information",
          type: "error",
        });
        return false;
      }
      return true;
    },
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    disableEntityVariableButton() {
      if (
        this.entityVariableField.relationship_key == "" ||
        this.entityVariableField.entityVariableInfo == ""
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.check {
  margin-bottom: 110px;
}
.step-text {
  line-height: 0.3;
  font: size 20px;
}
.main-content {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-card {
  border: 0.5px dashed #dcdfe6;
}
.image {
  margin-left: 10px;
}
.icon-container {
  margin-bottom: 20px;
}
.icon {
  width: 50px;
  height: 50px;
}
p {
  margin-right: 80px;
  margin-top: 20px;
}
.actions {
  display: flex;
  align-items: center;
  margin-left: 215px;
}
.req-changes {
  display: flex;
  flex-direction: column;
}
.req-changes span {
  font-size: 12px;
  margin-top: 10px;
}
.input-container {
  display: flex;
  align-items: center;
  margin-top: -30px;
}
.input-field-icon {
  margin-right: 8px;
}
.send-btn {
  margin-left: 8px;
  min-height: 32.6px;
  height: 17px;
  padding: 10px;
}
.btn {
  margin-left: 735px;
  margin-top: -35px;
  margin-bottom: 10px;
}
.regenerate {
  margin-top: 25px;
  margin-left: 106px;
}
.regenerate span {
  font-size: 18px;
  margin-left: -97px;
}
.column-box {
  margin-left: 135px;
  margin-bottom: 10px;
}
.column-box span {
  font-size: 12px;
}
.generate-button {
  margin-top: 20px;
  width: 100%;
}
.el-icon {
  font-size: 50px;
  color: #409eff;
}
.form-input {
  margin-top: 59px;
  width: 500px;
  margin-left: 203px;
  border-color: #ccc;
}
.text-bold {
  font-weight: bold;
  margin-right: 5px;
}
.uploaded-file-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  width: 36vw;
  height: 8vh;
}
.template-questionnaire {
  margin-left: 62px;
}
.table-container {
  margin-left: 200px;
  width: 361px;
  margin-bottom: 216px;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100%;
  height: 20%;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

@media (max-width: 480px) {
  .steps-container {
    margin-left: -277px;
  }
  .box-card p {
    margin-left: 55px;
  }
  footer {
    height: 33%;
  }
}
@media (max-width: 768px) {
  .responsive-button {
    margin-top: 5px !important;
    margin-left: 20px !important;
  }
  ::v-deep.check {
    margin-bottom: 200px !important;
  }
}
@media (max-width: 768px) {
  .table-container {
    width: 126% !important;
    margin-left: -1px !important;
    margin-bottom: 180px !important;
  }
}
@media (max-width: 768px) {
  .bottom-fields {
    width: 100%;
    margin-left: -144px;
  }

  .req-changes span {
    margin-left: -216px;
  }

  .input-container {
    margin-left: -27px;
  }
  .input-field-icon {
    width: 140px;
    margin-top: -110px;
    margin-left: -190px;
  }

  .send-btn {
    margin-left: 0px;
    margin-bottom: 120px;
  }
  .regenerate span {
    margin-left: -113px;
    position: relative;
    bottom: 55px;
  }
  .reg-btn {
    position: relative;
    bottom: 53px;
  }
  .column-box {
    margin-left: -253px;
    margin-top: 35px;
  }

  .field-type {
    display: flex;
    flex-direction: column !important;
  }
  .img {
    width: 20px;
  }
  .generate-button1 {
    width: 245% !important;
    margin-left: -190px;
  }
  .template-questionnaire {
    margin-left: -333px;
    margin-top: 114px;
  }
  ::v-deep .loading-content p {
    margin-left: 40px !important;
    word-break: normal;
  }
}
</style>
<style scoped>
.el-message-box__wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
@media (max-width: 420px) {
  ::v-deep.el-message-box__wrapper .el-message-box {
    width: 75% !important;
    margin-left: -58px !important;
  }
}

::v-deep.custom-radio-button .el-radio-button__inner {
  background-color: #1b487e;
  border-color: #1b487e;
  color: #fff;
}

::v-deep.custom-radio-group .el-radio-button__inner:hover {
  background-color: #1b487e;
}

::v-deep.custom-radio-group .el-radio-button__inner.is-active {
  background-color: #1b487e;
  border-color: #1b487e;
}

::v-deep.custom-radio-group
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-color: #1b487e;
  border-color: #1b487e;
  box-shadow: -1px 0 0 0 #1b487e;
}
.config-options-select {
  width: 100%;
}
.card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}

.align-right {
  margin-left: auto;
}
</style>
